<template>
  <div class="tp-header-top-menu d-flex align-items-center justify-content-end">
    <div class="tp-header-top-menu-item tp-header-lang">
      <span
        v-if="currentLanguage"
        @click="handleActive('lang')"
        class="tp-header-lang-toggle"
        id="tp-header-lang-toggle"
        >{{ currentLanguage.name }}</span
      >
      <ul
        :class="`${isActive === 'lang' ? 'tp-lang-list-open' : ''}`"
        @mouseleave="onClose"
      >
        <li v-for="(language, index) in languages" :key="index">
          <span
            v-if="currentLanguage.code != language.code"
            @click="setLocale(language.code)"
          >
            {{ language.name }}</span
          >
        </li>
      </ul>
    </div>
    <div class="tp-header-top-menu-item tp-header-currency">
      <span
        @click="handleActive('currency')"
        class="tp-header-currency-toggle"
        id="tp-header-currency-toggle"
        >{{ currentCurrency?.code ?? "" }}</span
      >
      <ul
        :class="`${isActive === 'currency' ? 'tp-currency-list-open' : ''}`"
        @mouseleave="onClose"
      >
        <li v-for="(currency, index) in currencies" :key="index">
          <a
            href="javascript:;"
            @click="setCurrentCurrency(currency)"
            v-if="currentCurrency.code != currency.code"
            >{{ currency.code }}</a
          >
        </li>
      </ul>
    </div>
    <div class="tp-header-top-menu-item tp-header-setting">
      <span
        @click="handleActive('setting')"
        class="tp-header-setting-toggle"
        id="tp-header-setting-toggle"
        >{{ $t("setting") }}</span
      >
      <ul
        :class="`${isActive === 'setting' ? 'tp-setting-list-open' : ''}`"
        @mouseleave="onClose"
      >
        <li v-if="isAuth">
          <nuxt-link-locale href="/profile">{{
            $t("profile")
          }}</nuxt-link-locale>
        </li>
        <li v-else>
          <nuxt-link-locale href="/login">{{ $t("login") }}</nuxt-link-locale>
        </li>
        <li>
          <nuxt-link-locale href="/wishlist">{{
            $t("wishlist")
          }}</nuxt-link-locale>
        </li>
        <li>
          <nuxt-link-locale href="/cart">{{ $t("cart") }}</nuxt-link-locale>
        </li>
        <li v-if="isAuth">
          <a href="#" @click="logout">{{ $t("logout") }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useCurrencyStore } from "../../../pinia/useCurrencyStore";
import { storeToRefs } from "pinia";
import type { ICurrency } from "../../../types/currency-type";
import { useHomeStore } from "../../../pinia/useHomeStore";
import { useUtilityStore } from "../../../pinia/useUtilityStore";
import type { ILanguage } from "../../../types/languague-type";
import { useAuthStore } from "@/pinia/useAuthStore";
const { locale, setLocale } = useI18n();
// handle active

const { currentCurrency, currencies } = storeToRefs(useCurrencyStore());
const { getHomeData } = useHomeStore();
const { getAllCurrencies } = useCurrencyStore();
const languages = ref<ILanguage[]>([
  {
    name: "English",
    code: "en",
  },
  {
    name: "العربية",
    code: "ar",
  },
]);
const currentLanguage = computed(() =>
  languages.value.find((lang) => lang.code === locale.value)
);
const { setLanguage } = useUtilityStore();
const switchLocalePath = useSwitchLocalePath();
let isActive = ref<string>("");
const handleActive = (type: string) => {
  if (type === isActive.value) {
    isActive.value = "";
  } else {
    isActive.value = type;
  }
};
const logout = () => {
  isActive.value = "";
  signOut();
};
const setCurrentCurrency = (currency: ICurrency) => {
  currentCurrency.value = currency;
  isActive.value = "";
  getHomeData();
};

const setCurrentLanguage = async (language: ILanguage) => {
  isActive.value = "";
  await setLanguage(language);
};
const { isAuth, signOut } = useAuthStore();
const route = useRoute();
watch(
  () => route.path,
  () => {
    isActive.value = "";
  }
);
const onClose = () => {
  isActive.value = "";
};
// await getAllCurrencies();
</script>
